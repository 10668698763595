import Page from '@/components/Page/Page';
import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import ESolFooter from '@uikit/components/ESolFooter/ESolFooter';
import ErrorModule from '@uikit/components/ErrorModule/ErrorModule';
import Layout from '@uikit/components/Layout/Layout';
import type { EnterFrameCallbackEvent } from '@uikit/components/Lottie/interfaces';
import NewHeader from '@uikit/components/NewHeader/NewHeader';
import Section from '@uikit/components/Section/Section';
import type { AnimationItem } from 'lottie-web';
import animationData from './404-animation.json';

enum Animation404FrameTime {
  LoopStart = 50,
  LoopEnd = 230,
}

const ESolNotFoundPage = (): JSX.Element => {
  const handleEnterFrame = (
    $event: EnterFrameCallbackEvent,
    animationItem: AnimationItem
  ) => {
    if (Math.round($event.currentTime) === Animation404FrameTime.LoopEnd) {
      animationItem.goToAndPlay(Animation404FrameTime.LoopStart, true);
    }
  };

  const meta = {
    title: '404 | Seite nicht gefunden',
    noindex: true,
    description: 'Diese Seite konnte nicht gefunden werden',
    path: '',
  };

  useInitialPageDataLayer();

  return (
    <Layout>
      <Page meta={meta}>
        <NewHeader hasPageHeader={false} hasPreHeader={false} variant="esol" />

        <Section>
          <ErrorModule
            animationData={animationData}
            handleEnterFrame={handleEnterFrame}
            errorMessage={
              'Die gewünschte Seite existiert leider nicht. Möglicherweise wurde sie verschoben, oder haben Sie sich vertippt?'
            }
          />
        </Section>
      </Page>
      <ESolFooter />
    </Layout>
  );
};

export default ESolNotFoundPage;
